import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import N11V9A8I from "../images/N11V9A8I.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component"

const AppointmentDirection = () => {
  return (
    <Layout>
      <Seo title="Contact Colorado Canine Orthopedics" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>
                Contact
                <br />
                Colorado Canine Orthopedics
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <LazyLoadImage
              effect="blur"
              src={N11V9A8I}
              alt="Colorado Canine Orthopedics & Rehab"
              className="img-fluid mb-4"
            />
          </Col>
          <Col md={12}>
            <p className="mb-2">
              <span className="text-blue">
                <strong>
                  Colorado Canine Orthopedics is located in the Veterinary
                  Specialty Center at:
                </strong>
              </span>
              <br />
              5528 N. Nevada Ave. Colorado Springs, CO 80918
            </p>
            <p className="mb-2">
              <span className="text-blue">
                <strong>Phone:</strong>
              </span>
              &nbsp;
              <a href="tel:7192646666" className="tel-cta">
                (719) 264-6666
              </a>
            </p>
            <p>
              <span className="text-blue">
                <strong>Hours:</strong>
              </span><br />
              7 am – 5 pm Monday, Tuesday, Thursday, and Friday<br />
              7 am – 6:30 pm Wednesday<br />
              7 am – 3 pm Saturday
            </p>
          </Col>
          <Col md={6}>
            <p>
              Colorado Canine Orthopedics is located 0.4 mile south of 1-25 &
              Nevada Ave. and 0.7 mile north of Garden of the Gods & Nevada Ave.
            </p>
            <p>
              <strong>Directions from Northern Colorado Springs/ Denver</strong>
            </p>
            <ul className="appointment-list">
              <li>From southbound I-25, take the Nevada exit 148.</li>
              <li>Turn left (north) on Nevada Ave.</li>
              <li>Travel south for 0.4 mile.</li>
              <li>
                Turn right (west) at the Veterinary Specialty Center sign.
              </li>
            </ul>
            <p>*The driveway is shared with Sunset Creek Apartments.</p>
            <p>
              <strong>Directions from Southern Colorado Springs/Pueblo</strong>
            </p>
            <ul className="appointment-list">
              <li>From northbound I-25, take exit 148.</li>
              <li>Turn right at the second stoplight on North Nevada Ave.</li>
              <li>Travel south for 0.4 mile.</li>
              <li>
                Turn right (west) at the Veterinary Specialty Center sign.
              </li>
            </ul>
            <p>*The driveway is shared with Sunset Creek Apartment.</p>
          </Col>
          <Col md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6209.374909040308!2d-104.81756!3d38.908262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9d2883801ce87f7f!2sColorado%20Canine%20Orthopedics%20%26%20Rehab!5e0!3m2!1sen!2sus!4v1596017962095!5m2!1sen!2sus"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              // tabindex="0"
              title="map"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default AppointmentDirection
